<template>
    <div>
        <v-card :elevation="$vuetify.breakpoint.smAndDown ? 0 : 10">
            <v-card-title class="font-weight-light">
                <v-row>
                    <v-col cols="12" lg="6" class="mx-auto" :align="$vuetify.breakpoint.smAndDown ? 'center' : null">
                        <div :class="{'body-1': $vuetify.breakpoint.xsOnly}">{{ $t(`headers['profile edit']`) }}</div>
                    </v-col>
                    <v-col cols="12" lg="6" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'end'">
                        <div>
                            <v-btn text :href="`/users/${getUser.ID}` | webLink" target="_blank" small color="primary">
                                {{ $t(`actions['open site']`) }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-tabs class="profile-settings__tabs"
                        background-color="transparent"
                        slider-color="success"
                        mobile-breakpoint="400"
                        show-arrows
                        color="white"
                        grow
                        v-model="tab">
                    <v-tab :ripple="false" class="profile-settings__tab">{{ $t(`tabs['info']`) }}</v-tab>
                    <v-tab :ripple="false" class="profile-settings__tab">{{ $t(`tabs['password change']`) }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>

                        <v-row>
                            <v-col cols="12" lg="3" class="mx-auto" align="center">
                                <div>
                                    <div class="profile-settings__avatar">
                                        <v-img height="140"
                                               width="140"
                                               :src="getUserAvatar | getMedia"/>
                                        <div class="profile-settings__avatar-select d-flex align-center justify-center"
                                             @click="onClickChangeAvatar">
                                            <v-icon size="56" color="white">mdi-pencil-box-multiple-outline</v-icon>
                                        </div>
                                    </div>
                                    <div class="text-center mt-2">
                                        <v-btn @click="onClickChangeWallpaper()" text small color="primary">
                                            {{$t(`actions['change wallpapers']`)}}
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="9">
                                <div class="py-0" :class="{'ml-6': $vuetify.breakpoint.lgAndUp}">
                                    <user-name-form ref="user" @submit="onSubmitName"/>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="text-center title font-weight-light py-6">
                            <change-password-form ref="password" @submit="onSubmitPassword"/>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <image-cropper-modal :visible.sync="visibleCropper"
                             :viewport="getCropperViewport"
                             @submit="onSubmitCropper">
            <template slot="hint" v-if="!isChangeAvatar">
                {{ $t(`messages['avatar size']`) }}
            </template>
        </image-cropper-modal>
    </div>
</template>
<script>
import ImageCropperModal from "./modal/ImageCropperModal"
import UserNameForm from "./UserNameForm"
import ChangePasswordForm from "./ChangePasswordForm"
import { mapGetters, mapActions } from "vuex"

export default {
    components: { ImageCropperModal, UserNameForm, ChangePasswordForm },
    data() {
        return {
            tab: 0,
            visibleCropper: false,
            cropperMode: "",
        }
    },
    computed: {
        ...mapGetters("common", ["getUser"]),
        getUserAvatar() {
            return this.getUser.Avatar.Cropped
        },

        getAvatarViewport() {
            return { width: 160, height: 160, type: "circle" }
        },

        getWallpaperViewport() {
            return { width: 3384, height: 882, useRatio: true }
        },

        isChangeAvatar() {
            return this.cropperMode === "avatar"
        },

        getCropperViewport() {
            return this.isChangeAvatar ? this.getAvatarViewport : this.getWallpaperViewport
        },
    },

    methods: {
        ...mapActions("user", ["setAvatar", "setName", "setWallpaper", "attachPassword"]),
        ...mapActions("common", ["fetchUser"]),
        onClickChangeAvatar() {
            this.cropperMode = "avatar"
            this.visibleCropper = true
        },

        onClickChangeWallpaper() {
            this.cropperMode = "wallpaper"
            this.visibleCropper = true
        },

        loadingCall(caleee, loadingText, successText, errorText, onDone) {
            this.$dialog.loading({ text: loadingText }).then(dialog => {
                caleee()
                    .then(() => this.fetchUser())
                    .then(() => {
                        this.$dialog.message.success(successText)
                        onDone && onDone()
                    })
                    .catch(() => {
                        this.$dialog.message.error(errorText)
                    })
                    .finally(() => dialog.close())
            })
        },

        onSubmitCropper(...args) {
            this.visibleCropper = false
            if (this.isChangeAvatar) {
                this.onSubmitUserAvatar(...args)
            } else {
                this.onSubmitUserWallpaper(...args)
            }
        },

        onSubmitUserAvatar(params) {
            this.loadingCall(
                () => this.setAvatar(params),
                this.$t(`messages['photo uploads']`),
                this.$t(`messages['profile upload success']`),
                this.$t(`messages['picture upload error']`),
            )
        },

        onSubmitUserWallpaper(params) {
            this.loadingCall(
                () => this.setWallpaper(params),
                this.$t(`messages['photo uploads']`),
                this.$t(`messages['wallpaper profile success']`),
                this.$t(`messages['edit error']`),
            )
        },

        onSubmitName(attrs) {
            this.loadingCall(
                () => this.setName(attrs),
                this.$t(`messages['settings saves']`),
                this.$t(`messages['profile upload success']`),
                this.$t(`messages['picture upload error']`),
            )
        },

        onSubmitPassword({ password }) {
            this.loadingCall(
                () => this.attachPassword(password),
                this.$t(`messages['password in progress']`),
                this.$t(`messages['password success']`),
                this.$t(`messages['password change error']`),
                () => this.$refs.password.clear(),
            )
        },

        onClickChangeBackground() {
        // eslint-disable-next-line no-console
            console.log("onClickChangeBackground")
        },
    },
}
</script>
<style lang="stylus">
    .profile-settings__tab {
        z-index 2
        height 48px
        margin-top: 2px;

        &.v-tab--active:hover:before {
            opacity 0 !important
        }
    }

    .profile-settings__tabs .v-tabs-bar {
        height 52px
    }

    .profile-settings__tabs .v-tabs-slider {
        border-radius 4px
    }

    .profile-settings__tabs .v-tabs-slider-wrapper {
        height 100% !important
    }

    .profile-settings__avatar {
        position: relative;
        height: 140px;
        width: 140px;

        .v-image {
            border-radius 100%
        }

        .profile-settings__avatar-select {
            position absolute
            width 100%
            height 100%
            background rgba(0, 0, 0, 0.5)
            left 0
            top 0
            border-radius 100%
            opacity 0
            transition opacity linear 0.2s
        }

        .profile-settings__avatar-select:hover {
            cursor pointer
        }
    }

    .profile-settings__avatar:hover .profile-settings__avatar-select {
        opacity 1
    }
</style>
