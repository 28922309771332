<template>
    <div>
        <v-form lazy-validation ref="form" @submit.prevent="onSubmit">
            <div>
                <v-text-field :rules="[fieldRequired]" v-model="model.firstName" :label="$t(`labels['your name']`)"></v-text-field>
            </div>
            <div>
                <v-text-field :rules="[fieldRequired]" v-model="model.lastName" :label="$t(`labels['your lastname']`)"></v-text-field>
            </div>
            <div class="text-right">
                <v-btn class="mr-2" color="error" text @click="clearForm()" :disabled="isDisabledActions">
                    {{ $t("actions.cancel") }}
                </v-btn>
                <v-btn outlined ripple color="blue-grey darken-1" :disabled="isDisabledActions"
                       type="submit">
                    {{ $t("actions.save") }}
                </v-btn>
            </div>
        </v-form>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import Format from "../util/format"

export default {
    data() {
        return {
            model: { firstName: "", lastName: "" },
            fieldRequired: val => !!val || this.$t(`errors['field required']`),
        }
    },
    computed: {
        ...mapGetters("common", ["getUser", "getLocale"]),
        getFirstName() {
            return Format.getName(this.getUser.Name.First, "")
        },

        getLastName() {
            return Format.getName(this.getUser.Name.Last, "")
        },

        isDisabledActions() {
            return this.getFirstName === this.model.firstName &&
          this.getLastName === this.model.lastName
        },
    },
    watch: {
        getFirstName: {
            immediate: true,
            handler(val) {
                this.model.firstName = String(val || "")
            },
        },
        getLastName: {
            immediate: true,
            handler(val) {
                this.model.lastName = String(val || "")
            },
        },
    },

    methods: {
        clearForm() {
            this.model.firstName = String(this.getFirstName || "")
            this.model.lastName = String(this.getLastName || "")
        },

        onSubmit() {
            if (!this.$refs.form.validate()) {
                return
            }

            const lang = this.getLocale.toUpperCase()
            const { First, Last } = this.getUser.Name

            this.$emit("submit", {
                First: Object.assign(First, { [lang]: this.model.firstName }),
                Last: Object.assign(Last, { [lang]: this.model.lastName }),
            })
        },
    },
}
</script>
