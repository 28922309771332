<template>
    <div class="page-settings">
        <portal to="toolbar">
            <v-tabs center-active
                    class="gray-tabs toolbar-tabs" slider-color="orange" background-color="blue-grey lighten-5"
                    v-model="tab">
                <v-tab to="#main">
                    {{ $t(`tabs['info']`) }}
                </v-tab>

                <v-tab to="#uploads" v-if="isPhotographer">
                    {{ $t(`tabs['uploaded photos']`) }}
                </v-tab>

                <v-tab to="#promo" v-if="isPhotographer">
                    {{ $t(`tabs['promo']`) }}
                </v-tab>
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">
            <v-tab-item :transition="false" :reverse-transition="false" id="main" class="transparent-body">
                <!--                <div class="d-flex justify-center my-10">-->
                <v-col cols="12" md="7" class="mx-auto my-10">
                    <profile-settings/>
                </v-col>
                <!--                </div>-->
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" id="uploads" class="transparent-body">
                <photos-control photographer-scope>
                    {{ $t(`headers['sets and sessions']`) }}
                </photos-control>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false" id="promo" class="transparent-body">
                <photographer-promo/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import ProfileSettings from "../components/ProfileSettings"
import PhotosControl from "../components/photos/PhotosControl"
import PhotographerPromo from "../components/photographer/PhotographerPromo"

export default {
    components: { ProfileSettings, PhotosControl, PhotographerPromo },
    head() {
        return {
            title: this.$t("titles.profile"),
            toolbar: this.$t("toolbar.profile"),
        }
    },
    data() {
        return {
            tab: this.$route.hash || "main",
        }
    },
    computed: {
        ...mapGetters("common", ["getUser", "isPhotographer"]),
    },
}
</script>
