<template>
    <v-container fluid>
        <h2 class="headline mb-4">{{ $t(`tabs['promo']`) }}</h2>
        <v-row>
            <v-col cols="12" sm="6" md="3" lg="3" v-for="(tile, index) in tiles" :key="index">
                <v-card max-width="500" class="mx-auto" @click="tile.action" :disabled="tile.disabled">
                    <v-list-item :disabled="tile.disabled">
                        <v-list-item-avatar tile size="60">
                            <v-icon size="60">{{tile.icon}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle text-wrap">{{tile.text}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <modal-create-promo :options="promoOptions" :type="currentPromoType" :visible.sync="visiblePromoModal"
                            @submit="onSubmitPromo"/>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ModalCreatePromo from "../modal/ModalCreatePromo"
import LazyPromo from "../../services/LazyPromo"
import Format from "../../util/format"

export default {
    components: { ModalCreatePromo },
    data() {
        return {
            visiblePromoModal: false,
            currentPromoType: null,
            promoOptions: {},
            tiles: [
                {
                    text: this.$t(`labels['print codes']`),
                    icon: "mdi-smart-card-outline",
                    action: () => this.onClickCreateCard(),
                },
                {
                    text: this.$t(`labels['print badge']`),
                    icon: "mdi-badge-account-horizontal-outline",
                    action: () => this.onClickCreateBadge(),
                },
            ],
        }
    },
    computed: {
        ...mapGetters("common", ["getUser"]),
        photographerName() {
            return Format.getUserName(this.getUser.Name)
        },
        getAvatar() {
            return Format.getMedia(this.getUser.Avatar.Original, "default")
        },
    },
    methods: {
        ...mapActions("promo", ["createPhotographerCardCodes", "createPhotographerBadgeCode"]),
        onClickCreateCard() {
            this.promoOptions.image = this.getAvatar
            this.promoOptions.name = this.photographerName
            this.currentPromoType = "card"
            this.visiblePromoModal = true
        },

        onClickCreateBadge() {
            this.promoOptions.image = this.getAvatar
            this.promoOptions.name = this.photographerName
            this.currentPromoType = "badge"
            this.visiblePromoModal = true
        },

        onSubmitPromo({ cropped }) {
            this.visiblePromoModal = false
            if (this.currentPromoType === "card") {
                this.onSubmitPromoCard(cropped)
            } else {
                this.onSubmitPromoBadge(cropped)
            }
        },

        onSubmitPromoCard(blob) {
            LazyPromo().then(Promo => {
                return this.$dialog.prompt({
                    title: this.$t(`messages['cards per page']`, [Promo.constructor.cardsPerPage()]),
                    text: this.$t(`labels['set pages']`),
                    width: 580,
                })
            }).then(inputPages => {
                if (inputPages === false) {
                    return
                }

                const pages = parseInt(inputPages, 10) || 1
                this.$dialog.loading({ text: this.$t(`messages['cards progress']`) }).then(dialog => {
                    this.renderCards(blob, pages)
                        .finally(() => dialog.close())
                })
            })
        },

        onSubmitPromoBadge(blob) {
            this.$dialog.loading({ text: this.$t(`messages['badge progress']`) }).then(dialog => {
                this.renderBadge(blob)
                    .finally(() => dialog.close())
            })
        },

        renderCards(image, pages) {
            return LazyPromo().then(Promo => {
                const perPage = Promo.constructor.cardsPerPage()
                return this.createPhotographerCardCodes({ photographerId: this.getUser.ID, count: perPage * pages })
            }).then(codes => {
                return codes.map(({ Url, Code }) => ({
                    name: this.promoOptions.name,
                    picture: image,
                    qr: { url: Url, text: Code },
                }))
            }).then(cards => LazyPromo().then(Promo => Promo.createA4Cards(cards).draw()))
                .then(a4 => a4.print())
        },

        renderBadge(image) {
            return Promise.all([
                LazyPromo(),
                this.createPhotographerBadgeCode(this.getUser.ID),
            ]).then(([Promo, url]) => {
                return Promo.createBadge({
                    name: this.promoOptions.name,
                    background: image,
                    qr: { url: url },
                }).draw()
            }).then(badge => badge.print())
        },

    },
}
</script>
