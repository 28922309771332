<template>
    <div>
        <form @submit.prevent="onSubmit">
            <div>
                <v-text-field :error="hasError('password')"
                              :error-messages="firstError('password')"
                              v-model="model.password"
                              type="password"
                              :label="$t(`labels['new password']`)"></v-text-field>
            </div>

            <div>
                <v-text-field
                    :error="hasError('confirm')"
                    :error-messages="firstError('confirm')"
                    v-model="model.confirm"
                    type="password"
                    :label="$t(`labels['repeat password']`)"></v-text-field>
            </div>

            <div class="text-right">
                <v-btn class="mr-2"
                       color="error"
                       text
                       :disabled="isDisabledClear"
                       @click="clear()">
                    {{ $t("actions.discard") }}
                </v-btn>
                <v-btn outlined ripple
                       :disabled="isDisabledClear"
                       color="blue-grey darken-1"
                       type="submit">
                    {{ $t("actions.save") }}
                </v-btn>
            </div>
        </form>
    </div>
</template>
<script>
import Form from "storemoodify/lib/Form"

export default {
    mixins: [Form],
    data() {
        return {
            model: { confirm: "", password: "" },
        }
    },

    computed: {
        isDisabledClear() {
            return this.model.password === "" || this.model.confirm === ""
        },
    },

    methods: {
        defaultValue() {
            return { confirm: "", password: "" }
        },

        getConstraints() {
            return {
                "password": {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                    length: {
                        minimum: 6,
                        tooShort: this.$t(`errors['password length']`),
                    },
                },
                "confirm": {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                    equality: {
                        attribute: "password",
                        message: this.$t(`errors['passwords differ']`),
                    },
                },
            }
        },
    },
}
</script>
<style>

</style>
